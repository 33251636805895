// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import {getStorage} from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAKFiZ7jqBlO1_o4887ihyrzzsowSv5Enc",
  authDomain: "magasin-foucard.firebaseapp.com",
  projectId: "magasin-foucard",
  storageBucket: "magasin-foucard.appspot.com",
  messagingSenderId: "131102325681",
  appId: "1:131102325681:web:6ed2371002973b92fd9b90"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const store = getFirestore(app)
export const storage= getStorage(app)