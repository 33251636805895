import './style.css'

import logo from '../../assets/images/cadre1/logoEcoleDavid.png'
import mail from '../../assets/images/cadre1/email.png'
import Horloge from '../../assets/images/cadre1/Horloge.png'
import Lieu from '../../assets/images/cadre1/Lieu.png'
import Phone from '../../assets/images/cadre1/phone-call.png'
import Boutique from '../../assets/images/cadre1/IMG_1661.jpg'
import flèche from '../../assets/images/cadre1/flèche.png'
import ImageObjet from '../../assets/images/guitareTest/guitare.png'
import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { storage } from '../../firebase.config'
import { getDownloadURL, ref } from 'firebase/storage'

function ObjectPage() {
    const navigate = useNavigate()
    let location = useLocation()
    let object = location.state.data
    let horaires = location.state.horaires
    

    useEffect(() => {

        window.scrollTo(
            {
                top: 0,
            }
        )

    }, [location])


    function formatDescription(text) {
        let finalText = [];
        let newText = text.split('%');
    
        for (let i = 0; i < newText.length; i++) {
            if (newText[i].includes('#')) {
                finalText.push(<h3 className="customTitle" key={i}>{newText[i].split('#')[1]}</h3>);
            } else if (newText[i].includes('@')) {
                // Handle colored text
                const colorMatches = newText[i].match(/@(\w+)\{([^}]+)\}/);
                if (colorMatches) {
                    const color = colorMatches[1];
                    const coloredText = colorMatches[2];
                    finalText.push(
                        <p className="customParagraph" key={i}>
                            <span style={{ color: color }}>{coloredText}</span>
                        </p>
                    );
                }
            } else if (newText[i].includes('*')) {
                finalText.push(
                    <p className="customParagraph" key={i}>
                        <b className="customBold">{newText[i].split('*')[1]}</b>
                    </p>
                );
            } else {
                finalText.push(<p className="customParagraph" key={i}>{newText[i]}</p>);
            }
        }
    
        return finalText;
    }
    
    
    
    return (
        <div className="PageObject" onLoad="window.scrollTo(0, 0)">
            <header className='PageObject-header'>
                <div></div>
                <h1>{object.nom}</h1>
                <h2>{object.model}</h2>
            </header>
            <div className='cadre2'>
                <div className='ImageObjet'>
                    <img src={location.state.url} alt="photo de la guitare" />

                </div>
                <div className='RetourShop'>
                    <button onClick={() => { navigate('/', { state: { filtres: { prix: location.state.prix, cat: location.state.cat } } }) }}>
                        <img src={flèche} />
                        <p>Retour/Shop</p>
                    </button>
                </div>
                <div className='InfoObjet'>
                    <p>{object.nom}</p>
                    <p>{object.model}</p>
                    <p className='ÉtatOrange'>Etat: {object.etat}</p>
                    <p>{object.prixFix} €</p>
                </div>
                <div className='Description'>
                    {formatDescription(object.description)} 
                </div>
                <div className='HoraireCadre'>
                    <div className='Horaire'>
                        <div className='horloge'>
                            <img src={Horloge} />
                        </div>
                        <div className='text'>
                            <table>
                                <tr>
                                    <td>Mardi</td>
                                    <td>{horaires['mardi']}</td>
                                </tr>
                                <tr>
                                    <td>Mercredi</td>
                                    <td>{horaires['mercredi']}</td>
                                </tr>
                                <tr>
                                    <td>Vendredi</td>
                                    <td>{horaires['vendredi']}</td>
                                </tr>
                                <tr>
                                    <td>Samedi</td>
                                    <td>{horaires['samedi']}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='TelephoneCadre'>
                    <div className='Telephone'>
                        <div className='IconTelephone'>
                            <img src={Phone} />
                        </div>
                        <div className='NumeroPhone'>
                            <p>06 63 46 01 05</p>
                        </div>
                    </div>
                </div>
                <div className='EmailCadre'>
                    <div className='Mail'>
                        <div className='IconMail'>
                            <img src={mail} />
                        </div>
                        <div className='DirectionMail'>
                            <p> david.foucard@gmail.com </p>
                        </div>
                    </div>
                </div>
                <div className='RueCadre'>
                    <div className='Rue'>
                        <div className='IconLieu'>
                            <img src={Lieu} />
                        </div>
                        <div className='DirectionRue'>
                            <p> 12 Rue Pasteur, 71100 Chalon-sur-Saône </p>
                        </div>
                    </div>
                </div>
                <div className='LineGris'> </div>
                <div className='GuitareBasse'>
                    <img src={logo} />
                </div>
            </div>



        </div>);

}

export default ObjectPage;