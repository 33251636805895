
import './App.css';
import logo from './assets/images/cadre1/logoEcoleDavid.png';
import tel from './assets/images/cadre1/phone-call.png'
import Annonce from './component/Annonce/Annonce';
import Enveloppe from './assets/images/cadre1/email.png'
import Lieu from './assets/images/cadre1/Lieu.png'
import Horloge from './assets/images/cadre1/Horloge.png'
import { useEffect, useState, useRef } from 'react';
import { store } from './firebase.config';
import { getDocs, collection, getDoc, doc } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import image from './assets/images/sprint.svg';

function App() {
  // recuperer si jamais l'utilisateur reviens de la page objet
  const location = useLocation();

  const [objects, setObjects] = useState([])
  const [firstTime, setFirstTime] = useState(true);

  const [categorie, setCategorie] = useState('');
  const [prix, setPrix] = useState('');

  // get data from custom pour phrases et horaires
  const [acceuilPhrase, setAcceuilPhrase] = useState('');
  const [sousTitre, setSousTitre] = useState('');
  const [horaires, setHoraires] = useState({});

  // je vais chercher avec cette ref les filtres pour update si besoin 
  const prixSelectorRef = useRef();
  const categorieSelectorRef = useRef();



  async function initPage() {
    setFirstTime(false)
    const list = []
    const documents = await getDocs(collection(store, "object"));
    documents.docs.forEach((doc) => {

      list.push({ ...doc.data(), id: doc.id })
    })
    const custom = await getDoc(doc(store, "custom/3OLblya5h9o2STSSkXs8"));
    setAcceuilPhrase(custom.data().acceuil.split('%'))
    setSousTitre(custom.data().sousTitre.split('%'))

    setHoraires(custom.data().horaire)
    setObjects(list.sort((a, b) => b.date - a.date))
    //Cette fonction va etre executé si l'utilisateur reviens de la page objet et avait selectionné des filtres
    if (location.state.filtres != null) {
      setCategorie(location.state.filtres.cat)
      setPrix(location.state.filtres.prix)
      filtrList()
    }



  }
  async function filtrList() {
    const _selectorPrix = prixSelectorRef.current
    const _selectorCategorie = categorieSelectorRef.current

    _selectorPrix.value = prix
    _selectorCategorie.value = categorie




  }
  const clickVisite = () => {
    //ouvre la page google
    window.open('https://maps.app.goo.gl/7Fu7xmWCVnfqsDpQ7', '_blank')

  }

  useEffect(() => {
    firstTime ? initPage() : filtrList()



  }, [categorie, acceuilPhrase, sousTitre, prix])

  return (
    <div className="App">
      <header className="App-header">
        <div className='orange-line'></div>
        <h1>SHOP</h1>
        <h2>Vente d'instruments d'occasion</h2>
      </header>
      <body>
        <div className='cadre'>
          <div className='column1'>
            <img className='logoEcoleStore' src={logo} />
            <div className='telephone'>
              <img className='logoTel' src={tel} />
              <a href='tel:0663460105'><p className='numeroTel'>06 63 46 01 05</p></a>
            </div>
            <div className='mail'>
              <img className='logoEnveloppe' src={Enveloppe} />
              <a href="mailto:david.foucard@gmail.com"><p className='directionMail'>david.foucard@gmail.com</p></a>
            </div>
            <div className='direction'>
              <img className='logoDirection' src={Lieu} />
              <a href='https://www.google.com/maps/place/Ecole+Guitare+et+Basse+David+Foucard/@46.7802991,4.8517176,15z/data=!4m5!3m4!1s0x0:0x1ea2d31be3737675!8m2!3d46.7803015!4d4.8517144' target='blank'> <p className='directionRue'>12 Rue Pasteur,71100 Chalon-sur-Saône</p></a>
            </div>
          </div>
          <div className='column2'>
            <p className='DavidFucardName'>DAVID FOUCARD</p>
            <p className='Profession'>PROFESSEUR & MUSICIEN</p>
          </div>
          <div className='column3'>
            <img className='MontreDessin' src={Horloge} />
            <table className='horaire'>
              <tr>
                <td>Mardi</td>
                <td>{horaires['mardi']}</td>
              </tr>
              <tr>
                <td>Mercredi</td>
                <td>{horaires['mercredi']}</td>
              </tr>
              <tr>
                <td>Vendredi</td>
                <td>{horaires['vendredi']}</td>
              </tr>
              <tr>
                <td>Samedi</td>
                <td>{horaires['samedi']}</td>
              </tr>
            </table>
           <div className='visite'  style={{ cursor: 'pointer' }} onClick={clickVisite}><img src={image} /><p>Cliquez pour visiter !</p></div>


          </div>
        </div>
        <p className='EcoleDeMusique' onClick={() => { window.open('http://ecole-guitare-basse.fr/', '_blank') }}>École Guitare et Basse</p>

        <p className='QuiDeMieux'>{acceuilPhrase.length > 0 ? acceuilPhrase.map((e) => <p>{e}</p>) : <p> {acceuilPhrase}</p>}</p>


        <div className='filtres'>
          <select ref={categorieSelectorRef} className='ToutesCategories' onChange={(value) => { setCategorie(value.target.value) }}>
            <option value=''>Catégories</option>
            <option value='Electrique'>Electrique</option>
            <option value='Folk'>Folk</option>
            <option value='Classique'>Classique</option>
            <option value='Electroacc'>Electroaccoustique</option>
            <option value="Gaucher">Gaucher</option>
            <option value='Basse'>Basse</option>
            <option value='Accessoires'>Accessoires (cordes, mediator, pied, pupitre, house, sangle)</option>
            <option value='AmpBasse'>Ampli basse</option>
            <option value='AmpGuitare'>Ampli guitare</option>
            <option value='AmpElectroacc'>Ampli electroaccoustique</option>
          </select>
          <select ref={prixSelectorRef} className='prix' onChange={(value) => { setPrix(value.target.value) }}>
            <option value=''>Prix</option>
            <option value='0-100'>Accessoires (0-100)</option>
            <option value='100-300'>Entre de gamme (100-300)</option>
            <option value='+300'>Moyenne gamme (+300)</option>
            <option value='+700'>Haute gamme (+700)</option>
            <option value="+2000">Très haute gamme(+2000)</option>
          </select>
        </div>
        <p className='guitaresEtAmplis'>{sousTitre.length > 0 ? sousTitre.map((e) => <p>{e}</p>) : <p>{sousTitre}</p>}</p>
        <div className='annonces'>
          {
            prix == '' ? objects.sort((a, b) => b.date - a.date).filter(object => object.categorie === categorie || categorie === '').filter(object => object.prix == prix || prix == '').map((object, index) => <div key={object.id}><Annonce prix={prix} cat={categorie} data={object} key={index} horaires={horaires} /></div>) : objects.sort((a, b) => parseInt(a.prixFix) - parseInt(b.prixFix)).filter(object => object.categorie === categorie || categorie === '').filter(object => object.prix == prix || prix == '').map((object, index) => <div key={object.id}><Annonce prix={prix} cat={categorie} data={object} key={index} horaires={horaires} /></div>)
          }

        </div>
      </body>
    </div>
  );
}

export default App;
