import './style.css'
import { Link, useNavigate } from "react-router-dom"
import { useEffect, useState } from 'react'
import { storage } from '../../firebase.config'
import { getDownloadURL, ref } from 'firebase/storage'

function Annonce(props) {

    const [url, setUrl] = useState()
    const [animate, setAnimate] = useState(false)

    async function initUrl() {
        const theUrl = await getDownloadURL(ref(storage, 'images/' + props.data.id))
        setUrl(theUrl)
    }

    useEffect(() => {
        initUrl()
    }, [url])

    return <Link to={{ pathname: "/annonce" }} state={{ data: props.data, url: url, horaires: props.horaires, prix: props.prix, cat: props.cat }} preventScrollReset={true} onClick={() => { }}>
        <div className='annonce' style={animate ? { transform: 'scale(5)' } : null} >
            <div className='image' style={{ backgroundImage: "url(" + url + ")" }}> </div>
            <div className='infos'>
                <p>Nom : {props.data.nom}</p>
                <p className='model'>Model : {props.data.model}</p>
                <p>Prix : {props.data.prixFix != null ? props.data.prixFix : props.data.prix} €</p>
            </div>
        </div>
    </Link>



}

export default Annonce;